import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>EXTRACTION</h2>
      <h3>What is it?</h3>
      <p>A tooth which is very decayed or damaged, or loose because of gum disease, may have to be extracted (taken out of your mouth).  Wisdom teeth sometimes have to be extracted if they have come through at an awkward angle and are causing problems (such as decay in adjacent teeth or, being difficult to clean, and causing infections).</p>
      <p>Teeth are sometimes taken out from children's mouths to help other teeth which are crowded to grow straight, (usually on the advice of an orthodontist).</p>
      <h3>What will my dentist do?</h3>
      <p>Some teeth are easier to take out than others. A local anaesthetic (an injection in your mouth) will be used to numb the tooth before it is extracted; this is the best treatment for most patients.  In some cases, for children or nervous patients, or where a tooth might prove difficult to remove, sedation (something to make you relaxed) with a local anaesthetic may be used.  On rare occasions a general anaesthetic may be considered. If a general anaesthetic is needed, you will have to go to a hospital or a specialist treatment centre (you are likely to be able to go home the same day, but not all patients are able to do so).</p>
      <p>While the tooth is being taken out</p>
      <p>You may hear some noise and feel some pressure as the tooth is being eased out- but you should not feel pain. Sometimes stitches re put into the gum to minimise any post extraction bleeding, to make the area more comfortable and help it heal quickly.</p>
      <p>Afterwards:</p>
      <p>You may need a day or so off work to recover, depending on how difficult the extraction was and whether sedation or a general anaesthetic was used.  Most people experience very little post-operation discomfort. The dentist will ensure that the bleeding has stopped before you leave the practice. You should not smoke or drink for at least 24 hours after an extraction.</p>
      <p>The dentist  will give  you advice  on:</p>
      <ul>
        <li>How to look after the space where  the tooth was while  it is healing</li>
        <li>Which painkillers are suitable to use so you are not in any discomfort when the anaesthetic wears off</li>
        <li>How  to contact the practice if there  are any  problems.</li>
      </ul>
    </LightboxLayout>
  )
}

export default Page